<template>
  <div>
    <div>
      <!-- table -->
      <data-table
        :columns="columns"
        requesturl="/barcodes/tat"
        export-csv-url="/barcodes/tat/csv"
        export-excel-url="/barcodes/tat/excel"
        :server-params-modifier="serverParamsModifier"
      />

    </div>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import { formatDateToMonthShort } from '@/@core/utils/filter'

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      selectedBarcode: null,
      pageLength: 20,
      columns: [
        {
          label: 'DU Barcode',
          field: 'duBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'ST Barcode',
          field: 'stBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'SA Barcode',
          field: 'saBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'COR Barcode',
          field: 'corBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit User ID',
          field: 'kitUser',
          formatFn: value => (value === '' || value.length === 0 ? 'Not Registered' : value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Type',
          field: 'kitType',
          formatFn: value => (value.trim().replace(/^\w/, c => c.toUpperCase())),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Country',
          field: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Date of kit Pickup Request',
          field: 'kitPickupRequestedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Pickup Scheduled Date',
          field: 'kitPickupScheduledAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'kit Pickup TAT',
          field: 'kitPickupTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Received Date',
          field: 'kitReceivedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Date of Sequencing Prep Completion',
          field: 'sequencingPrepCompletedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Sequencing Prep Completion TAT',
          field: 'sequencingPrepCompletedTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Date of Sequencing Completion',
          field: 'sequencingCompletedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Sequencing Completion TAT',
          field: 'sequencingCompletedTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Date of Data Analysis Completion',
          field: 'dataAnalysisCompletedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Data Analysis Completion TAT',
          field: 'dataAnalysisCompletedTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Reports Published Date',
          field: 'reportsPublishedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Reports Published TAT',
          field: 'reportsPublishedTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Latest Reports Published Date',
          field: 'latestReportPublishedAt',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Latest Reports Published TAT',
          field: 'latestReportPublishedTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Overall TAT',
          field: 'overallTat',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
      ],
    }
  },
  computed: {

  },
  methods: {
    serverParamsModifier(serverParams) {
      const serverParamsClone = JSON.parse(JSON.stringify(serverParams))
      serverParamsClone.columnFilters.kitUser = { $ne: '' }
      serverParamsClone.columnFilters.isObsolete = { $ne: true }
      return serverParamsClone
    },

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
